import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <div id="about"></div>
      <Features
        subheading={<Subheading></Subheading>}
        heading={
          <>
            Advancing Healthcare With <HighlightedText>AI.</HighlightedText>
          </>
        }
      />
      <MainFeature
        heading="Our Vision"
        description="Tackling the big gaps in the healthcare sector to provide better patient care, prevent illness, promote wellness, lower the costs for the sector and contribute to saving lives."
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
      />
      <MainFeature
        heading="Our Mission"
        description="Automate and optimize the healthcare sector operations through our cutting edge AI products."
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
        textOnLeft={false}
      />
      <MainFeature
        heading="Our Passion"
        description="We work hard on providing the best and most innovative products for the healthcare sector to empower it to provide better patient care."
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
        textOnLeft={true}
      />
      <MainFeature
        heading="Our Alignment"
        description="We partner with our clients and investors for the long haul tackling inefficiencies, high costs, fraud and abuse in the healthcare sector. We are proud of our contribution to help save lives."
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
        textOnLeft={false}
      />
      {/* <FeatureWithSteps
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Get Started.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      /> */}
      <div id="products">
        <MainFeature2
          imageSize="800px"
          subheading={<Subheading>Our Products</Subheading>}
          heading={
            <>
              Linnaea <HighlightedText>OCR</HighlightedText>
            </>
          }
          imageSrc="/logo-ocr.png"
          showDecoratorBlob={false}
          description="The first functioning and reliable  product delivering our proprietary OCR for handwritten Arabic and English to be applied to medical prescriptions and claim forms with data extraction in tabular form.. We are a highly technical product."
          // features={[
          //   {
          //     Icon: MoneyIcon,
          //     title: "Affordable",
          //     description:
          //       "We promise to offer you the best rate we can - at par with the industry standard.",
          //     iconContainerCss: tw`bg-green-300 text-green-800`,
          //   },
          //   {
          //     Icon: BriefcaseIcon,
          //     title: "Professionalism",
          //     description:
          //       "We assure you that our templates are designed and created by professional designers.",
          //     iconContainerCss: tw`bg-red-300 text-red-800`,
          //   },
          // ]}
        />
      </div>
      {/* <div id="pricing">
        <Pricing
          subheading={<Subheading>Pricing</Subheading>}
          heading={
            <>
              Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
            </>
          }
          plans={[
            {
              name: "Personal",
              price: "$17.99",
              duration: "Monthly",
              mainFeature: "For Individuals",
              features: [
                "30 Templates",
                "7 Landing Pages",
                "12 Internal Pages",
                "Basic Assistance",
              ],
            },
            {
              name: "Business",
              price: "$37.99",
              duration: "Monthly",
              mainFeature: "For Small Businesses",
              features: [
                "60 Templates",
                "15 Landing Pages",
                "22 Internal Pages",
                "Priority Assistance",
              ],
              featured: true,
            },
            {
              name: "Enterprise",
              price: "$57.99",
              duration: "Monthly",
              mainFeature: "For Large Companies",
              features: [
                "90 Templates",
                "27 Landing Pages",
                "37 Internal Pages",
                "Personal Assistance",
              ],
            },
          ]}
        />
      </div> */}

      {/* <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Clients <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            heading: "Amazing User Experience",
            quote:
              "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
            customerName: "Charlotte Hale",
            customerTitle: "Director, Delos Inc.",
          },
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
            heading: "Love the Developer Experience and Design Principles !",
            quote:
              "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
            customerName: "Adam Cuppy",
            customerTitle: "Founder, EventsNYC",
          },
        ]}
      />
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "Are all the templates easily customizable ?",
            answer:
              "Yes, they all are. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
          },
          {
            question:
              "How long do you usually support an standalone template for ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
          },
          {
            question: "What kind of payment methods do you accept ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
          },
          {
            question:
              "Is there a subscribption service to get the latest templates ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
          },
          {
            question: "Are the templates compatible with the React ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
          },
          {
            question: "Do you really support Internet Explorer 11 ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
          },
        ]}
      /> */}
      <div id="contact">
        <GetStarted
          text="Book A Demo"
          secondaryLinkText="Contact Us"
          secondaryLinkUrl="info@perpartners.com"
        />
      </div>
      <Footer />
    </AnimationRevealPage>
  );
};
